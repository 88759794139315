import axios from "axios";
import { useContext, useMemo } from "react";
import AppContext from "../../AppContext/AppContext";
import { toast } from "react-toastify";

function useAxiosInstance() {
  const { employee, logout } = useContext(AppContext);

  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL + "api/employees/",
      headers: {
        Authorization: "Bearer " + employee?.token,
      },
    });

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return error;
      }
      // error => {

      //     if (error.response && error.response.status === 401) {
      //         toast.error("Please login again,Your Token is expired")
      //         logout();
      //     }
      //     return Promise.reject(error);
      // }
    );

    return instance;
  }, [employee, logout]);

  return axiosInstance;
}

export default useAxiosInstance;
