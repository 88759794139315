import React from 'react'
import RoleManageHeader from './components/RoleManageHeader'
import RoleManageContent from './components/RoleManageContent'
import {
  useHasRole,
  ROLES,
} from '../../../../config/accesscontrol/chebcenter/ChebCenter'
import { useAccount } from 'wagmi'

export default function RoleManage() {
  const { address } = useAccount()
  const isDefaultAdmin = useHasRole(ROLES.DEFAULT_ADMIN_ROLE, address)

  if (!address) {
    return (
      <div className='flex items-center justify-center h-full'>
        <div className='text-center p-8'>
          <h2 className='text-xl font-medium text-white'>
            Wallet Not Connected
          </h2>
          <p className='mt-2 text-white'>
            Please connect your wallet to access the role management system.
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className='crud_holder'>
      <RoleManageHeader />
      {isDefaultAdmin.data ? <RoleManageContent /> : `You can't access here`}
    </div>
  )
}
