import { useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import NumberInput from "../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { SIZE_API } from "../../../../../Utilities/APIs/APIs";
import SelectInput from "../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import { toast } from "react-toastify";
function CreateSize({ setShowCreateForm, setShowModal, triggerFetch, type }) {
  const axiosInstance = useAxiosInstance();

  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [precedence, setPrecedence] = useState(1);
  const [parcelDimension, setParcelDimension] = useState({
    length: "",
    width: "",
    height: "",
    weight: "",
  });

  console.log(parcelDimension, name, gender, " parcelDimension");

  function validateMeasurement(value) {
    // Check if the value is a valid positive number (integer or float)
    return /^[+]?\d*\.?\d+$/.test(value) && parseFloat(value) > 0;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!gender || !name) {
      toast.error("Please fill all the fields");
      return;
    }

    if (
      parcelDimension.length == "" ||
      parcelDimension.width == "" ||
      parcelDimension.height == "" ||
      parcelDimension.weight == "" ||
      !validateMeasurement(parcelDimension.length) ||
      !validateMeasurement(parcelDimension.width) ||
      !validateMeasurement(parcelDimension.height) ||
      !validateMeasurement(parcelDimension.weight)
    ) {
      toast.error("Please enter valid dimension value");
      return;
    }

    const itemData = {
      name,
      gender,
      type,
      precedence,
      ...parcelDimension,
    };

    const response = await axiosInstance.post(
      SIZE_API + "/createSize",
      itemData
    );

    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Size Name`}
        value={name}
        placeholder={`Enter Size Name`}
        setState={setName}
      />

      <ShortTextInput
        label={`Please Enter Product Height(inches)`}
        value={parcelDimension.height}
        placeholder={`Please Enter Product Height(inches)`}
        setState={setParcelDimension}
        fieldName="height"
      />

      <ShortTextInput
        label={`Please Enter Product Weight(OZ)`}
        value={parcelDimension.weight}
        placeholder={`Please Enter Product Weight(OZ)`}
        setState={setParcelDimension}
        fieldName="weight"
      />

      <ShortTextInput
        label={`Please Enter Product Length(inches)`}
        value={parcelDimension.length}
        placeholder={`Please Enter Product Length(inches)`}
        setState={setParcelDimension}
        fieldName="length"
      />
      <ShortTextInput
        label={`Please Enter Product Width(inches)`}
        value={parcelDimension.width}
        placeholder={`Please Enter Product Width(inches)`}
        setState={setParcelDimension}
        fieldName="width"
      />

      <SelectInput setState={setGender}>
        <option disabled hidden selected>
          Gender
        </option>
        <option value="men">Men</option>
        <option value="women">Women</option>
      </SelectInput>

      {/* <NumberInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter Precedence`}
        setState={setPrecedence}
      /> */}

      <FormSubmitButton text="Create Size" />
    </Form>
  );
}

export default CreateSize;
