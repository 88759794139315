'use client'

import {
  useReadContract,
  useWriteContract,
  useAccount,
  useTransactionReceipt,
} from 'wagmi'
import abi from './abi/abi.json'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const CHEBLISTING_ADDRESS = process.env.REACT_APP_CHEB_LISTING_ADDRESS

export const useAddToWhitelist = (setIsWhitelisted) => {
  const [txHash, setTxHash] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { data: receipt } = useTransactionReceipt({
    hash: txHash,
    enabled: !!txHash,
  })

  useEffect(() => {
    if (receipt && receipt.status === 'success') {
      setIsWhitelisted(true)
      toast.success('Address is added to Whitelist successfully.')
    }
  }, [receipt])

  const { writeContract } = useWriteContract({
    mutation: {
      onError(error) {
        console.error('Error adding to whitelist:', error.message)
        setIsPending(false)
      },
      onSuccess(tx) {
        setTxHash(tx)
        setIsPending(false)
      },
    },
  })

  const { isConnected } = useAccount()

  const addAddresses = async (addresses) => {
    try {
      setIsPending(true)
      await writeContract({
        address: CHEBLISTING_ADDRESS,
        abi,
        functionName: 'addToWhitelist',
        args: [addresses],
        enabled: isConnected,
      })
    } catch (error) {
      console.error('Error adding addresses to whitelist:', error)
      setIsPending(false)
      throw error
    }
  }
  return { addAddresses, isPending }
}

export const useRemoveFromWhitelist = (setIsWhitelisted) => {
  const [txHash, setTxHash] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { data: receipt } = useTransactionReceipt({
    hash: txHash,
    enabled: !!txHash,
  })

  useEffect(() => {
    if (receipt && receipt.status === 'success') {
      setIsWhitelisted(false)
      toast.success('Address is removed from Whitelist successfully.')
    }
  }, [receipt])

  const { writeContract } = useWriteContract({
    mutation: {
      onError(error) {
        console.error('Error adding to whitelist:', error.message)
        setIsPending(false)
      },
      onSuccess(tx) {
        setTxHash(tx)
        setIsPending(false)
      },
    },
  })

  const { isConnected } = useAccount()

  const removeAddresses = async (addresses) => {
    try {
      setIsPending(true)
      await writeContract({
        address: CHEBLISTING_ADDRESS,
        abi,
        functionName: 'removeFromWhitelist',
        args: [addresses],
        enabled: isConnected,
      })
    } catch (error) {
      console.error('Error adding addresses to whitelist:', error)
      setIsPending(false)
      throw error
    }
  }
  return { removeAddresses, isPending }
}

export const useAddToBlacklist = (setIsBlacklisted, setIsWhitelisted) => {
  const [txHash, setTxHash] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { data: receipt } = useTransactionReceipt({
    hash: txHash,
    enabled: !!txHash,
  })

  useEffect(() => {
    if (receipt && receipt.status === 'success') {
      setIsBlacklisted(true)
      setIsWhitelisted(false)
      toast.success('Address is added to Blacklist successfully.')
    }
  }, [receipt])

  const { writeContract } = useWriteContract({
    mutation: {
      onError(error) {
        console.error('Error adding to blacklist:', error.message)
        setIsPending(false)
      },
      onSuccess(tx) {
        setTxHash(tx)
        setIsPending(false)
      },
    },
  })

  const { isConnected } = useAccount()

  const addAddresses = async (addresses) => {
    try {
      setIsPending(true)
      await writeContract({
        address: CHEBLISTING_ADDRESS,
        abi,
        functionName: 'addToBlacklist',
        args: [addresses],
        enabled: isConnected,
      })
    } catch (error) {
      console.error('Error adding addresses to blacklist:', error)
      setIsPending(false)
      throw error
    }
  }
  return { addAddresses, isPending }
}

export const useRemoveFromBlacklist = (setIsBlacklisted) => {
  const [txHash, setTxHash] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { data: receipt } = useTransactionReceipt({
    hash: txHash,
    enabled: !!txHash,
  })

  useEffect(() => {
    if (receipt && receipt.status === 'success') {
      setIsBlacklisted(false)
      toast.success('Address is removed from Blacklist successfully.')
    }
  }, [receipt])

  const { writeContract } = useWriteContract({
    mutation: {
      onError(error) {
        console.error('Error adding to blacklist:', error.message)
        setIsPending(false)
      },
      onSuccess(tx) {
        setTxHash(tx)
        setIsPending(false)
      },
    },
  })

  const { isConnected } = useAccount()

  const removeAddresses = async (addresses) => {
    try {
      setIsPending(true)
      await writeContract({
        address: CHEBLISTING_ADDRESS,
        abi,
        functionName: 'removeFromBlacklist',
        args: [addresses],
        enabled: isConnected,
      })
    } catch (error) {
      console.error('Error adding addresses to blacklist:', error)
      setIsPending(false)
      throw error
    }
  }
  return { removeAddresses, isPending }
}

export const useIsWhitelisted = (account) => {
  const { isConnected } = useAccount()
  // Fetch initial whitelist status
  return useReadContract({
    address: CHEBLISTING_ADDRESS,
    abi,
    functionName: 'isWhitelisted',
    args: [account],
    enabled: isConnected && Boolean(account),
  })

}

export const useIsBlacklisted = (account) => {
  const { isConnected } = useAccount()

  return useReadContract({
    address: CHEBLISTING_ADDRESS,
    abi,
    functionName: 'isBlacklisted',
    args: [account],
    enabled: isConnected && Boolean(account),
    watch: true,
  })
}

export const useAreWhitelisted = (accounts) => {
  const { isConnected } = useAccount()

  return useReadContract({
    address: CHEBLISTING_ADDRESS,
    abi,
    functionName: 'areWhitelisted',
    args: [accounts],
    enabled:
      isConnected &&
      Boolean(accounts) &&
      accounts.length > 0 &&
      accounts.length <= 50,
    watch: true,
  })
}

export const useAreBlacklisted = (accounts) => {
  const { isConnected } = useAccount()

  return useReadContract({
    address: CHEBLISTING_ADDRESS,
    abi,
    functionName: 'areBlacklisted',
    args: [accounts],
    enabled:
      isConnected &&
      Boolean(accounts) &&
      accounts.length > 0 &&
      accounts.length <= 50,
    watch: true,
  })
}

export const useContractPause = () => {
  const { writeContract, isPending, data } = useWriteContract()
  const { isConnected } = useAccount()

  const pauseContract = async () => {
    try {
      writeContract({
        address: CHEBLISTING_ADDRESS,
        abi,
        functionName: 'pause',
        enabled: isConnected,
        watch: true,
      })
    } catch (error) {
      console.error('Error pausing contract:', error)
      throw error
    }
  }

  return { pauseContract, isPending, data }
}

export const useContractUnpause = () => {
  const { writeContract, isPending, data } = useWriteContract()
  const { isConnected } = useAccount()

  const unpauseContract = async () => {
    try {
      writeContract({
        address: CHEBLISTING_ADDRESS,
        abi,
        functionName: 'unpause',
        enabled: isConnected,
        watch: true,
      })
    } catch (error) {
      console.error('Error unpausing contract:', error)
      throw error
    }
  }

  return { unpauseContract, isPending, data }
}

export const useIsContractPaused = () => {
  const { isConnected } = useAccount()

  return useReadContract({
    address: CHEBLISTING_ADDRESS,
    abi,
    functionName: 'paused',
    enabled: isConnected,
  })
}
