import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'
import { useContext } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './App.css'
import '@rainbow-me/rainbowkit/styles.css'
import { getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { WagmiProvider } from 'wagmi'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { skaleCalypsoTestnet } from 'wagmi/chains';

import NavBar from './Partials/Sections/NavBar/NavBar'
import SideBar from './Partials/Sections/SideBar/SideBar'
import Login from './Screens/AuthScreens/Login/Login'
import Dashboard from './Screens/Dashboard/Dashboard'
import InviteEmployees from './Screens/AuthScreens/InviteEmployees/InviteEmployees'
import EmployeesScreen from './Screens/AuthScreens/EmployeesScreen'
import EmployeeScreen from './Screens/AuthScreens/Employees/EmployeeScreen'
import Employees from './Screens/AuthScreens/Employees/Employees'
import Register from './Screens/AuthScreens/Register/Register'
import RecoverPassOne from './Screens/AuthScreens/RecoverPass/RecoverPassOne/RecoverPassOne'
import RecoverPassThree from './Screens/AuthScreens/RecoverPassThree'

import UsersScreen from './Screens/UsersScreen/UsersScreen'

import Customers from './Screens/UsersScreen/Customers/Customers'

import AppContext from '../AppContext/AppContext'
import Sellers from './Screens/UsersScreen/Sellers/Sellers'
import SellerStore from './Screens/SellerStore/SellerStore'
import Products from './Screens/SellerStore/Products/Products'
import Address from './Screens/Address/Address'
import City from './Screens/Address/City/City'
import Street from './Screens/Address/Street/Street'
import AddressesFromCustomer from './Screens/UsersScreen/Customers/AddressesFromCustomer/AddressesFromCustomer'
import OrderNavlink from './Screens/SellerStore/Order/OrderNavlink/OrderNavlink'
import Order from './Screens/SellerStore/Order/Order'
import OrderItems from './Screens/SellerStore/Order/OrderItems/OrderItems'
import ProductFeatures from './Screens/Product/ProductFeatures/ProductFeatures'
import ProductSize from './Screens/Product/ProductFeatures/ProductSize/ProductSize'
import ProductColor from './Screens/Product/ProductFeatures/ProductColor/ProductColor'
import Loader from './Partials/Elements/Loader/Loader'
import CustomerOrderNavlink from './Screens/UsersScreen/Customers/CustomerOrderNavlink/CustomerOrderNavlink'
import CustomerOrders from './Screens/UsersScreen/Customers/CustomerOrders/CustomerOrders'
import CustomerOrderItems from './Screens/UsersScreen/Customers/CustomerOrderItems/CustomerOrderItems'
import Payment from './Screens/Payment/Payment'
import SellerPayments from './Screens/UsersScreen/Sellers/SellerPayments/SellerPayments'
import NewProduct from './Screens/Product/NewProduct/NewProduct'
import ProductScreen from './Screens/Product/ProductScreen'
import SharedProductScreen from './Screens/Product/Products/SharedProductScreen'
import ViewProductRequest from './Screens/Product/ProductFeatures/ProductRequested/ViewProductRequest'
import Profile from './Screens/Profile/Profile'
import RoleManage from './Screens/UsersScreen/RoleManage'

const config = getDefaultConfig({
  appName: 'CheB',
  projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
  chains: [skaleCalypsoTestnet],
  ssr: true,
})

function App() {
  const { employee, isLoading } = useContext(AppContext)
  const queryClient = new QueryClient()

  // const dispatch = useDispatch()

  // axios.interceptors.response.use(
  //   (res) => {
  //     return res;
  //   },
  //   (err) => {
  //     if (err.response.status === 401) {
  //       dispatch(logout())
  //     }
  //     return Promise.reject(err);
  //   }
  // );

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider>
              <NavBar />
              <main>
                {employee && <SideBar />}
                <section className='screen-container'>
                  <Routes>
                    <Route
                      path='/login'
                      element={!employee ? <Login /> : <Navigate to={'/'} />}
                    />
                    <Route
                      path='/register/:token'
                      element={!employee ? <Register /> : <Navigate to={'/'} />}
                    />
                    <Route
                      path='/recover-pass-one'
                      element={<RecoverPassOne />}
                    />
                    <Route
                      path='/resetEmployeePassword/:token'
                      element={<RecoverPassThree />}
                    />

                    <Route
                      path='/employeeScreens'
                      element={
                        employee ? (
                          <EmployeesScreen />
                        ) : (
                          <Navigate to={'/login'} />
                        )
                      }
                    />
                    <Route
                      path='/employeeInvites'
                      element={
                        employee ? (
                          <InviteEmployees />
                        ) : (
                          <Navigate to={'/login'} />
                        )
                      }
                    />
                    <Route
                      path='/employeeScreen'
                      element={
                        employee ? (
                          <EmployeeScreen />
                        ) : (
                          <Navigate to={'/login'} />
                        )
                      }
                    />
                    <Route
                      path='/employees'
                      element={
                        employee ? <Employees /> : <Navigate to={'/login'} />
                      }
                    />
                    <Route
                      path='/customers'
                      element={
                        employee ? <Customers /> : <Navigate to={'/login'} />
                      }
                    />
                    <Route
                      path='/sellers'
                      element={
                        employee ? <Sellers /> : <Navigate to={'/login'} />
                      }
                    />

                    <Route
                      path='/'
                      element={
                        employee ? <Dashboard /> : <Navigate to={'/login'} />
                      }
                    />

                    <Route
                      path='/profile'
                      element={
                        employee ? <Profile /> : <Navigate to={'/login'} />
                      }
                    />

                    <Route
                      path='/usersScreen'
                      element={
                        employee ? <UsersScreen /> : <Navigate to={'/login'} />
                      }
                    />

                    <Route
                      path='/products'
                      element={
                        employee ? <Products /> : <Navigate to={'/login'} />
                      }
                    />
                    <Route
                      path='/seller/store/:sellerId'
                      element={
                        employee ? <SellerStore /> : <Navigate to={'/login'} />
                      }
                    />
                    <Route
                      path='/seller/payments/:sellerId'
                      element={
                        employee ? (
                          <SellerPayments />
                        ) : (
                          <Navigate to={'/login'} />
                        )
                      }
                    />
                    <Route
                      path='/seller/sellerStore/products/:storeId'
                      element={
                        employee ? <Products /> : <Navigate to={'/login'} />
                      }
                    />
                    <Route
                      path='/seller/sellerStore/orderScreen/:storeId'
                      element={
                        employee ? <OrderNavlink /> : <Navigate to={'/login'} />
                      }
                    />
                    <Route
                      path='/sellers/store/order/:storeId/:orderStatus'
                      element={
                        employee ? <Order /> : <Navigate to={'/login'} />
                      }
                    />
                    <Route
                      path='/sellers/store/orderItems/:orderId'
                      element={
                        employee ? <OrderItems /> : <Navigate to={'/login'} />
                      }
                    />

                    <Route
                      path='/address'
                      element={
                        employee ? <Address /> : <Navigate to={'/login'} />
                      }
                    />
                    <Route
                      path='/address/state/city/:stateId'
                      element={employee ? <City /> : <Navigate to={'/login'} />}
                    />
                    <Route
                      path='/address/state/city/street/:cityId'
                      element={
                        employee ? <Street /> : <Navigate to={'/login'} />
                      }
                    />

                    {/* for customer address */}
                    <Route
                      path='/address/:customerId'
                      element={
                        employee ? (
                          <AddressesFromCustomer />
                        ) : (
                          <Navigate to={'/login'} />
                        )
                      }
                    />

                    {/*product screen  */}
                    <Route
                      path='/productScreen'
                      element={
                        employee ? (
                          <ProductScreen />
                        ) : (
                          <Navigate to={'/login'} />
                        )
                      }
                    />
                    <Route
                      path='/productScreen/:type'
                      element={
                        employee ? (
                          <SharedProductScreen />
                        ) : (
                          <Navigate to={'/login'} />
                        )
                      }
                    />

                    <Route
                      path='/product-requested'
                      element={
                        employee ? (
                          <ViewProductRequest />
                        ) : (
                          <Navigate to={'/login'} />
                        )
                      }
                    />

                    <Route
                      path='/product/newProduct'
                      element={
                        employee ? <NewProduct /> : <Navigate to={'/login'} />
                      }
                    />

                    <Route
                      path='/product/features'
                      element={
                        employee ? (
                          <ProductFeatures />
                        ) : (
                          <Navigate to={'/login'} />
                        )
                      }
                    />

                    <Route
                      path='/product/size/:type'
                      element={
                        employee ? <ProductSize /> : <Navigate to={'/login'} />
                      }
                    />
                    <Route
                      path='/product/color/:type'
                      element={
                        employee ? <ProductColor /> : <Navigate to={'/login'} />
                      }
                    />

                    {/* customer order */}
                    <Route
                      path='/customer/orderScreen/:customerId'
                      element={
                        employee ? (
                          <CustomerOrderNavlink />
                        ) : (
                          <Navigate to={'/login'} />
                        )
                      }
                    />

                    <Route
                      path='/customer/order/:customerId/:orderStatus'
                      element={
                        employee ? (
                          <CustomerOrders />
                        ) : (
                          <Navigate to={'/login'} />
                        )
                      }
                    />

                    <Route
                      path='/customer/orderItems/:orderId'
                      element={
                        employee ? (
                          <CustomerOrderItems />
                        ) : (
                          <Navigate to={'/login'} />
                        )
                      }
                    />

                    {/* payment */}

                    {/* paymentToSeller */}
                    <Route
                      path='paymentToSeller'
                      element={
                        employee ? <Payment /> : <Navigate to={'/login'} />
                      }
                    />
                    <Route
                      path='roleManage'
                      element={
                        employee ? <RoleManage /> : <Navigate to={'/login'} />
                      }
                    />
                  </Routes>
                </section>
              </main>
            </RainbowKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </BrowserRouter>
      {isLoading && <Loader />}
    </>
  )
}

export default App
