import { useState } from "react";
import "./InputBoilerplate.css";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";

function InputBoilerplate({
  label,
  type,
  value,
  placeholder,
  setState,
  fieldName,
  inputIsTextArea,
}) {
  const [isShow, setIsShow] = useState(true);
  const [customInputType, setCustomInputType] = useState(type);

  function handleShow() {
    setIsShow(false);
    setCustomInputType("text");
  }

  function handleHidden() {
    setIsShow(true);
    setCustomInputType("password");
  }

  function ViewIcon() {
    return isShow ? (
      <RiEyeLine onClick={() => handleShow()} className="eye-icon-login" />
    ) : (
      <RiEyeOffLine onClick={() => handleHidden()} className="eye-icon-login" />
    );
  }
  return (
    <div className="input_group">
      <label className="input_field_label caption bold">{label}</label>
      {!inputIsTextArea && (
        <div className="input-group">
          <input
            className="input_field"
            type={customInputType}
            value={value}
            placeholder={placeholder}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (fieldName) {
                // Update parcel dimensions by spreading the previous state
                setState((prevState) => ({
                  ...prevState,
                  [fieldName]: inputValue,
                }));
              } else {
                // Update normal fields
                setState(inputValue);
              }
            }}
          />
          {type === "password" && <ViewIcon />}
        </div>
      )}
      {inputIsTextArea && (
        <textarea
          className="input_field"
          value={value}
          placeholder={placeholder}
          onChange={(e) => setState(e.target.value)}
        />
      )}
    </div>
  );
}

export default InputBoilerplate;
