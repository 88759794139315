import { useContext, useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import AppContext from "../../../../../AppContext/AppContext";
import { ALL_SELLER_API } from "../../../../../Utilities/APIs/APIs";

export default function useFetchSeller({toggleFetch}) {
  const { setIsLoading } = useContext(AppContext);
  const [sellers, setSellers] = useState([]);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetSellers() {
      setIsLoading(true);
      const { data } = await axiosInstance.get(ALL_SELLER_API);
      setSellers(data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    fetchAndSetSellers();
  }, [toggleFetch]);
  return sellers;
}
