import React from "react";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ImageCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ArchiveButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import DeleteButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";

export default function EmployeesTable({
  employee,
  employees,
  setShowModal,
  setShowDeleteSection,
  setTargetID,
  setArchive,
}) {
  return (
    <CRUDTable>
      <CRUDTableHeader>
        <CRUDth th="Image" />
        <CRUDth th="Name" />
        <CRUDth th="Email Address" />
        <CRUDth th="Level" />
        <CRUDth th="Actions" />
        {/* {employee.level === "admin" && <CRUDth th="Actions" />} */}
      </CRUDTableHeader>
      <tbody>
        {employees &&
          employees?.map((employee) => (
            <CRUDTableRow key={employee._id}>
              <ImageCell imgSrc={employee.dp} imgAlt={employee.name} />
              <ShortTextCell text={employee.name} />
              <ShortTextCell text={employee.email} />
              <ShortTextCell text={employee.level} />

              <td className="action_button_cell">
                <DeleteButton
                  product={employee}
                  setShowModal={setShowModal}
                  setShowDeleteSection={setShowDeleteSection}
                  targetID={employee._id}
                  setTargetID={setTargetID}
                  setArchive={setArchive}
                />
              </td>



              {/* <td className="action_button_cell">
                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={e._id}
                    isArchive={e.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                </td> */}

              {/* {employee.level === "admin" ? (
                <td className="action_button_cell">
                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={e._id}
                    isArchive={e.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                </td>
              ) : (
                ""
              )} */}
            </CRUDTableRow>
          ))}
      </tbody>
    </CRUDTable>
  );
}
