import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { Product_API } from "../../../../../Utilities/APIs/APIs";
import Pagination from "../../NewProduct/components/Pagination/Pagination";
import CRUDTable from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";


function ViewProductRequest() {
    const [product, setProducts] = useState(null)
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [totalProducts, setTotalProducts] = useState(0);
    const axiosInstance = useAxiosInstance();

    async function fetchProductRequest() {
        const { data } = await axiosInstance.get(Product_API + `getProductRequested?page=${page}`);
        setProducts(data.products);
        setTotalProducts(data.totalProducts);
        setPages(data.totalPage);
    }

    useEffect(() => {
        fetchProductRequest();
    }, [axiosInstance, page]);

    const handleActionChange = async (productId, action) => {
        const { data } = await axiosInstance.get(Product_API + `updateProductAction?id=${productId}&action=${action}`);
        console.log("data32", data)
        if (data) {
            fetchProductRequest();
        }
    }

    return (

        <div className="crud_holder">
            <div className="screen_header">
                <h1 className="screen_heading">
                    Products Requested ({product?.length} in total)
                </h1>
            </div>
            <CRUDTable>
                <CRUDTableHeader>
                    <CRUDth th="Description" />
                    <CRUDth th="Requested Date" />
                    <CRUDth th="Requested By" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {product?.map((product) => (
                        <CRUDTableRow key={product._id}>
                            <ShortTextCell text={product.description} />
                            <ShortTextCell text={new Date(product.createdAt).toLocaleDateString()} />
                            <ShortTextCell text={product?.requestedBy?.name} />

                            <td className="action_button_cell">
                                <select
                                    value={product.isPosted ? "close" : ""}
                                    onChange={(e) => handleActionChange(product._id, e.target.value)}
                                    disabled={product.isPosted}
                                >
                                    <option value="">Select Action</option>
                                    <option value="close">Close</option>
                                </select>
                            </td>
                        </CRUDTableRow>
                    ))}

                </tbody>
            </CRUDTable>
            <Pagination
                currentPage={page}
                setPage={setPage}
                handlePageChange={(page) => setPage(page)}
                totalItems={totalProducts}
                totalPageNumber={pages}
            />



        </div>


    );
};

export default ViewProductRequest;