import React, { useState } from 'react'
import Button from '../../../../Partials/Elements/Button/Button'
import {
  useIsContractPaused,
  useAddRoleMembers,
  useRemoveRoleMembers,
  useContractPause,
  useContractUnpause,
  useRevokeRole,
  useGrantRole,
  useRenounceRole,
  ROLES,
  useHasRole,
} from '../../../../../config/accesscontrol/chebcenter/ChebCenter'
import { toast } from 'react-toastify'
import { useAccount } from 'wagmi'

export default function RoleManageContent() {
  const {address} = useAccount()
  const [isBatch, setIsBatch] = useState(false)
  const [targetAddress, setTargetAddress] = useState(address )
  const [batchAddress, setBatchAddress] = useState('')
  const [selectedRole, setSelectedRole] = useState(ROLES.ADMIN_ROLE)

  const { data: isPaused } = useIsContractPaused()

  const { addMembers, isPending: isAddPending } = useAddRoleMembers()
  const { removeMembers, isPending: isRemovePending } = useRemoveRoleMembers()
  const { pauseContract, isPending: isPausePending } = useContractPause()
  const { unpauseContract, isPending: isUnpausePending } = useContractUnpause()
  const { grantRole, isPending: isGrantPending } = useGrantRole()
  const { revokeRole, isPending: isRevokePending } = useRevokeRole()
  const { renounceRole, isPending: isRenouncePending } = useRenounceRole()
  // const {data: isDefaultAdmin} = useHasRole(ROLES.DEFAULT_ADMIN_ROLE, targetAddress)
  // const {data: isAdmin} = useHasRole(ROLES.ADMIN_ROLE, targetAddress)
  // const {data: Verifier} = useHasRole(ROLES.Verifier, targetAddress)
  // const {data: isExecutive} = useHasRole(ROLES.EXECUTIVE_ROLE, targetAddress)

  const validateAddress = (address) => {
    return address && address.startsWith('0x') && address.length === 42
  }

  const handleSingleMemberOperation = async (operation) => {
    if (!validateAddress(targetAddress)) {
      toast.error('Please enter a valid address')
      return
    }

    try {
      switch (operation) {
        case 'grant':
          await grantRole(selectedRole, targetAddress)
          break
        case 'revoke':
          await revokeRole(selectedRole, targetAddress)
          break
        default:
          throw new Error('Invalid operation')
      }
      setTargetAddress('')
    } catch (error) {
      console.error(`Error ${operation}ing role:`, error)
      toast.error(`Failed to ${operation} role: ${error.message}`)
    }
  }

  const handleBatchOperation = async (operation) => {
    const addresses = batchAddress
      .split('\n')
      .map((addr) => addr.trim())
      .filter((addr) => validateAddress(addr))

    if (addresses.length === 0) {
      toast.error('Please enter valid addresses')
      return
    }
    if (addresses.length > 50) {
      toast.error('Maximum 50 addresses allowed')
      return
    }

    try {
      switch (operation) {
        case 'add':
          await addMembers(selectedRole, addresses)
          break
        case 'remove':
          await removeMembers(selectedRole, addresses)
          break
        default:
          throw new Error('Invalid operation')
      }
      setBatchAddress('')
    } catch (error) {
      console.error(`Error ${operation}ing members:`, error)
      toast.error(`Failed to ${operation} members: ${error.message}`)
    }
  }

  const handleRenounceRole = async () => {
    try {
      await renounceRole(selectedRole, targetAddress)
    } catch (error) {
      console.error('Error renouncing role:', error)
      toast.error('Failed to renounce role: ' + error.message)
    }
  }

  return (
    <div className='role_content'>
      <div className='role_container'>
        <div>
          <h2 className='text-4xl'>Contract State</h2>
          <p className={`${isPaused ? 'isPaused' : 'contract_active_text'}`}>
            {isPaused ? 'Contract is Paused' : 'Contract is Active'}
          </p>
        </div>
        <div>
          <Button
            handleAction={isPaused ? unpauseContract : pauseContract}
            disabled={isPausePending || isUnpausePending}
            Content={
              isPaused
                ? isUnpausePending
                  ? 'Unpausing...'
                  : 'Unpause Contract'
                : isPausePending
                ? 'Pausing...'
                : 'Pause Contract'
            }
          ></Button>
        </div>
      </div>
      <div className='role_manage_container'>
        <div className='role_manage_item_container'>
          <h2 className='text-4xl'>Manage Roles</h2>
        </div>
        <div className='role_manage_item_container batch_mode'>
          <input
            type='checkbox'
            checked={isBatch}
            onChange={(e) => setIsBatch(e.target.checked)}
          />
          <label>Batch Mode</label>
        </div>
        <div className='role_manage_item_container'>
          <div className='role_manage_item'>
            <label>Target Address</label>
            {isBatch ? (
              <textarea
                value={batchAddress}
                onChange={(e) => setBatchAddress(e.target.value)}
                className='address_input'
                placeholder='0x... (one address per line)'
                rows='5'
              />
            ) : (
              <input
                className='address_input'
                type='text'
                placeholder='0x...'
                value={targetAddress}
                onChange={(e) => setTargetAddress(e.target.value)}
              />
            )}
          </div>
          <div className='role_manage_item'>
            <label>Select Role</label>
            <select
              onChange={(e) => setSelectedRole(e.target.value)}
              value={selectedRole}
            >
              <option value={ROLES.ADMIN_ROLE}>Admin Role</option>
              <option value={ROLES.VERIFIER_ROLE}>Verifier Role</option>
              <option value={ROLES.EXECUTIVE_ROLE}>Executive Role</option>
            </select>
          </div>
        </div>
        <div className='role_manage_item_container'>
          {!isBatch ? (
            <>
              <button
                className='role_action_btn btn_grant'
                onClick={() => handleSingleMemberOperation('grant')}
                disabled={isGrantPending || !targetAddress}
              >
                {isGrantPending ? 'Granting...' : 'Grant Role'}
              </button>
              <button
                className='role_action_btn btn_revoke'
                onClick={() => handleSingleMemberOperation('revoke')}
                disabled={isRevokePending || !targetAddress}
              >
                {isRevokePending ? 'Revoking...' : 'Revoke Role'}
              </button>
              <button
                className='role_action_btn btn_renounce'
                onClick={handleRenounceRole}
                disabled={isRenouncePending}
              >
                {isRenouncePending ? 'Renouncing...' : 'Renounce Role'}
              </button>
            </>
          ) : (
            <>
              <button
                className='role_action_btn btn_grant'
                onClick={() => handleBatchOperation('add')}
                disabled={isAddPending || !batchAddress}
              >
                {isAddPending ? 'Adding...' : 'Add Members'}
              </button>
              <button
                className='role_action_btn btn_revoke'
                onClick={() => handleBatchOperation('remove')}
                disabled={isRemovePending || !batchAddress}
              >
                {isRemovePending ? 'Removing...' : 'Remove Members'}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
