import Modal from "../../../../../Partials/Elements/Modal/Modal";
import ArchiveItem from "../../../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import ViewSeller from "./components/ViewSeller";

export default function SellerModal({
  showModal,
  employee,
  handleClose,
  targetID,
  showViewSection,
  setShowModal,
  setShowDeleteSection,
  showDeleteSection,
  archive,
  triggerFetch
}) {
  return (
    <>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Seller`}
        >
          {showViewSection && (
            <ViewSeller targetID={targetID} employee={employee} />
          )}

          {showDeleteSection && (
            <ArchiveItem
              api={"archiveEmployee/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}

        </Modal>
      )}
    </>
  );
}
