import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";

import CRUDTableRow from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CRUDButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import DeleteButton from "../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";

import { RiBankCardLine, RiStore2Line } from "react-icons/ri";
import { FiUserPlus, FiUserX, FiXCircle } from "react-icons/fi";
import LoadingIcon from "../../../../Partials/Elements/Loader/LoadingIcon";

import {
  useIsContractPaused,
  useAddToWhitelist,
  useRemoveFromWhitelist,
  useAddToBlacklist,
  useRemoveFromBlacklist,
  useIsBlacklisted,
  useIsWhitelisted,
} from "../../../../../config/accesscontrol/core/cheblisting/ChebListing";
import { useNavigate } from "react-router-dom";
import { useAccount, useTransactionReceipt } from "wagmi";
import {
  ROLES,
  useHasRole,
} from "../../../../../config/accesscontrol/chebcenter/ChebCenter";

export default function SellerTableItem({
  seller,
  setShowModal,
  setTargetID,
  setShowViewSection,
  setShowDeleteSection,
  setArchive,
}) {
  const navigate = useNavigate();

  const { address } = useAccount();

  const [isWhitelisted, setIsWhitelisted] = useState();
  const [isBlacklisted, setIsBlacklisted] = useState();

  const { data: isAdmin } = useHasRole(ROLES.ADMIN_ROLE, address);
  const { data: isPaused } = useIsContractPaused();
  const { data: targetIsWhitelisted } = useIsWhitelisted(seller.walletAddress);
  const { data: targetIsBlacklisted } = useIsBlacklisted(seller.walletAddress);

  useEffect(() => {
    setIsWhitelisted(targetIsWhitelisted);
    setIsBlacklisted(targetIsBlacklisted);
  }, [targetIsWhitelisted]);

  const { addAddresses: addToWhitelist, isPending: isAddToWhitelistPending } =
    useAddToWhitelist(setIsWhitelisted);

  const {
    removeAddresses: removeFromWhitelist,
    isPending: isRemoveFromWhitelistPending,
  } = useRemoveFromWhitelist(setIsWhitelisted);

  const { addAddresses: addToBlacklist, isPending: isAddToBlacklistPending } =
    useAddToBlacklist(setIsBlacklisted, setIsWhitelisted);

  const {
    removeAddresses: removeFromBlacklist,
    isPending: isRemoveFromBlacklistPending,
  } = useRemoveFromBlacklist(setIsBlacklisted);

  async function handleAddToWhitelist() {
    if (!targetIsBlacklisted) {
      const addresses = [seller.walletAddress];

      try {
        await addToWhitelist(addresses);
      } catch (error) {
        console.error("Transaction error:", error);
        toast.error("Failed to add address to whitelist.");
      }
    } else {
      toast.error(
        "This account is in the blacklist. Please remove it from the blacklist first."
      );
    }
  }

  async function handleRemoveFromWhitelist() {
    let addresses = [seller.walletAddress];
    try {
      await removeFromWhitelist(addresses);
    } catch (error) {
      console.error("Transaction error:", error);
      toast.error("Failed to remove address from whitelist.");
    }
  }

  async function handleAddToBlacklist() {
    let addresses = [seller.walletAddress];
    try {
      await addToBlacklist(addresses);
    } catch (error) {}
  }

  async function handleRemoveFromBlacklist() {
    let addresses = [seller.walletAddress];
    try {
      await removeFromBlacklist(addresses);
    } catch (error) {
      console.error("Transaction error:", error);
      toast.error("Failed to remove address from Blacklist.");
    }
  }

  function handleConfirmAlert(handler) {
    if (!isPaused) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="px-10 py-4 bg-[#333333] w-[300px] gap-20 rounded-md">
            <h1 className="text-4xl text-white py-10">Are you sure?</h1>
            <div className="flex justify-end">
              <button
                className="bg-[#2a73c5] hover:bg-[#0078ff] text-white px-6 py-2 rounded-lg mr-6"
                onClick={() => {
                  handler();
                  onClose();
                }}
              >
                Yes
              </button>
              <button
                onClick={onClose}
                className="bg-[#e45858] hover:bg-[#e91616] text-white px-6 py-2 rounded-lg"
              >
                Cancel
              </button>
            </div>
          </div>
        ),
      });
    } else {
      alert("Contract is paused");
    }
  }

  return (
    <CRUDTableRow key={seller._id}>
      <ShortTextCell text={seller.name} />
      <ShortTextCell text={seller?.email} />
      <ShortTextCell text={seller?.walletAddress ?? ""} />
      <td className="action_button_cell">
        <ViewButton
          setShowModal={setShowModal}
          setShowViewSection={setShowViewSection}
          targetID={seller._id}
          setTargetID={setTargetID}
        />
        <CRUDButton
          dataTip="Stores"
          dataFor="Stores Item"
          handleClick={() => navigate(`/seller/store/${seller._id}`)}
        >
          <RiStore2Line />
        </CRUDButton>

        <CRUDButton
          dataTip="Payments"
          dataFor="Payments"
          handleClick={() => navigate(`/seller/payments/${seller._id}`)}
        >
          <RiBankCardLine />
        </CRUDButton>

        <DeleteButton
          product={seller}
          setShowModal={setShowModal}
          setShowDeleteSection={setShowDeleteSection}
          targetID={seller._id}
          setTargetID={setTargetID}
          setArchive={setArchive}
        />

        {seller.walletAddress && isAdmin && (
          <>
            <CRUDButton
              dataTip={
                isWhitelisted ? "Remove from Whitelist" : "Add to Whitelist"
              }
              dataFor={
                isWhitelisted ? "Remove from Whitelist" : "Add to Whitelist"
              }
              handleClick={() =>
                handleConfirmAlert(
                  isWhitelisted
                    ? handleRemoveFromWhitelist
                    : handleAddToWhitelist
                )
              }
            >
              {isAddToWhitelistPending || isRemoveFromWhitelistPending ? (
                <LoadingIcon />
              ) : isWhitelisted ? (
                <FiXCircle />
              ) : (
                <FiUserPlus />
              )}
            </CRUDButton>

            <CRUDButton
              dataTip={
                isBlacklisted ? "Remove from Blacklist" : "Add to Blacklist"
              }
              dataFor={
                isBlacklisted ? "Remove from Blacklist" : "Add to Blacklist"
              }
              handleClick={() =>
                handleConfirmAlert(
                  isBlacklisted
                    ? handleRemoveFromBlacklist
                    : handleAddToBlacklist
                )
              }
            >
              {isAddToBlacklistPending || isRemoveFromBlacklistPending ? (
                <LoadingIcon />
              ) : isBlacklisted ? (
                <FiXCircle />
              ) : (
                <FiUserX />
              )}
            </CRUDButton>
          </>
        )}
      </td>
    </CRUDTableRow>
  );
}
