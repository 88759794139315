import React from 'react'
import CRUDTable from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable'
import CRUDTableHeader from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader'
import CRUDth from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth'
import { useIsContractPaused } from '../../../../../config/accesscontrol/core/cheblisting/ChebListing'
import SellerTableItem from './SellerTableItem'

export default function SellerTable({
  sellers,
  setShowModal,
  setTargetID,
  setShowViewSection,
  setShowDeleteSection,
  setArchive,
}) {
  const { data: isPaused } = useIsContractPaused()
  return (
    <CRUDTable>
      <CRUDTableHeader>
        <CRUDth th='Seller Name' />
        <CRUDth th='Email' />
        <CRUDth th='Wallet Address' />
        <CRUDth th='Actions' />
      </CRUDTableHeader>
      <tbody>
        {sellers?.map((seller, index) => (
          <SellerTableItem
            key={index}
            seller={seller}
            setShowModal={setShowModal}
            setTargetID={setTargetID}
            setShowViewSection={setShowViewSection}
            setShowDeleteSection={setShowDeleteSection}
            setArchive={setArchive}
          />
        ))}
      </tbody>
    </CRUDTable>
  )
}
