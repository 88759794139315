import React from 'react'

export default function Button({ Content, handleAction, disabled }) {
  return (
    <button
      onClick={handleAction}
      disabled={disabled}
      type='button'
      className='button_primary'
      onMouseEnter={(e) => {
        e.target.style.backgroundColor = '#333'
        e.target.style.transform = 'scale(1.05)'
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = 'var(--primary-color)'
        e.target.style.transform = 'scale(1)'
      }}
      onFocus={(e) => {
        e.target.style.boxShadow = '0 0 0 3px rgba(255, 255, 255, 0.6)'
      }}
      onBlur={(e) => {
        e.target.style.boxShadow = 'none'
      }}
    >
      {Content}
    </button>
  )
}
