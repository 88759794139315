import { useContext, useState } from "react";
import AppContext from "../../../../AppContext/AppContext";
import NavbarLogo from "./components/NavbarLogo/NavbarLogo";
import UserImage from "./components/UserImage";
import UserDetails from "./components/UserDetails";
import DropdownButton from "./components/DropdownButton";
import DropDrownItems from "./components/DropDrownItems";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useDisconnect } from "wagmi";
import "./NavBar.css";
function NavBar() {
  const { employee } = useContext(AppContext);
  console.log(employee, "employee13");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav>
      <div className="nav_container">
        <NavbarLogo />

        {employee && (
          <>
            <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                // Ensure the button state is mounted and authentication is loaded
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                  ready &&
                  account &&
                  chain &&
                  (!authenticationStatus ||
                    authenticationStatus === "authenticated");

                return (
                  <div
                    {...(!ready && {
                      "aria-hidden": true,
                      style: {
                        opacity: 0,
                        pointerEvents: "none",
                        userSelect: "none",
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <button
                            onClick={openConnectModal}
                            type="button"
                            style={{
                              backgroundColor: "var(--primary-color)",
                              color: "white",
                              border: "2px solid",
                              padding: "12px 20px",
                              borderRadius: "12px",
                              fontSize: "16px",
                              fontWeight: "bold",
                              cursor: "pointer",
                              textAlign: "center",
                              transition: "all 0.3s ease",
                              display: "inline-flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = "#333";
                              e.target.style.transform = "scale(1.05)";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor =
                                "var(--primary-color)";
                              e.target.style.transform = "scale(1)";
                            }}
                            onFocus={(e) => {
                              e.target.style.boxShadow =
                                "0 0 0 3px rgba(255, 255, 255, 0.6)";
                            }}
                            onBlur={(e) => {
                              e.target.style.boxShadow = "none";
                            }}
                          >
                            Connect Wallet
                          </button>
                        );
                      }

                      if (chain.unsupported) {
                        return (
                          <button
                            onClick={openChainModal}
                            type="button"
                            style={{
                              backgroundColor: "var(--primary-color)",
                              color: "white",
                              border: "2px solid",
                              padding: "12px 20px",
                              borderRadius: "12px",
                              fontSize: "16px",
                              fontWeight: "bold",
                              cursor: "pointer",
                              textAlign: "center",
                              transition: "all 0.3s ease",
                              display: "inline-flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = "#333";
                              e.target.style.transform = "scale(1.05)";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor =
                                "var(--primary-color)";
                              e.target.style.transform = "scale(1)";
                            }}
                            onFocus={(e) => {
                              e.target.style.boxShadow =
                                "0 0 0 3px rgba(255, 255, 255, 0.6)";
                            }}
                            onBlur={(e) => {
                              e.target.style.boxShadow = "none";
                            }}
                          >
                            Wrong network
                          </button>
                        );
                      }

                      return (
                        <div style={{ display: "flex", gap: 12 }}>
                          <button
                            onClick={openChainModal}
                            type="button"
                            style={{
                              backgroundColor: "var(--primary-color)",
                              color: "white",
                              border: "2px solid",
                              padding: "12px 20px",
                              borderRadius: "12px",
                              fontSize: "16px",
                              fontWeight: "bold",
                              cursor: "pointer",
                              textAlign: "center",
                              transition: "all 0.3s ease",
                              display: "flex",
                              alignItems: "center",
                              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = "#333";
                              e.target.style.transform = "scale(1.05)";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor =
                                "var(--primary-color)";
                              e.target.style.transform = "scale(1)";
                            }}
                            onFocus={(e) => {
                              e.target.style.boxShadow =
                                "0 0 0 3px rgba(255, 255, 255, 0.6)";
                            }}
                            onBlur={(e) => {
                              e.target.style.boxShadow = "none";
                            }}
                          >
                            {chain.hasIcon && (
                              <div
                                style={{
                                  background: chain.iconBackground,
                                  width: 18,
                                  height: 18,
                                  borderRadius: "50%",
                                  overflow: "hidden",
                                  marginRight: 8,
                                }}
                              >
                                {chain.iconUrl && (
                                  <img
                                    alt={chain.name ?? "Chain icon"}
                                    src={chain.iconUrl}
                                    style={{ width: 18, height: 18 }}
                                  />
                                )}
                              </div>
                            )}
                            {chain.name}
                          </button>

                          <button
                            onClick={openAccountModal}
                            type="button"
                            style={{
                              backgroundColor: "var(--primary-color)",
                              color: "white",
                              border: "2px solid",
                              padding: "12px 20px",
                              borderRadius: "12px",
                              fontSize: "16px",
                              fontWeight: "bold",
                              cursor: "pointer",
                              textAlign: "center",
                              transition: "all 0.3s ease",
                              display: "inline-flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = "#333";
                              e.target.style.transform = "scale(1.05)";
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor =
                                "var(--primary-color)";
                              e.target.style.transform = "scale(1)";
                            }}
                            onFocus={(e) => {
                              e.target.style.boxShadow =
                                "0 0 0 3px rgba(255, 255, 255, 0.6)";
                            }}
                            onBlur={(e) => {
                              e.target.style.boxShadow = "none";
                            }}
                          >
                            {account.displayName}
                            {account.displayBalance
                              ? ` (${account.displayBalance})`
                              : ""}
                          </button>
                        </div>
                      );
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom>
            <div className="nav_user_wrapper">
              <UserImage employee={employee} />
              <UserDetails employee={employee} />
              <DropdownButton isOpen={isOpen} setIsOpen={setIsOpen} />
              <DropDrownItems isOpen={isOpen} />
            </div>
          </>
        )}
      </div>
    </nav>
  );
}

export default NavBar;
