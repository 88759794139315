import { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import {
  PRODUCT_ALL_IMAGE,
  Product_API,
} from "../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import slugify from "react-slugify";
import ImageInput from "../../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import MultiImageInput from "../../../../../Partials/Layouts/Forms/FormInputs/MultiImageInput/MultiImageInput";
import useFetchColorway from "../CreateProduct/Hooks/useFetchColorway";
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import { useParams } from "react-router-dom";

function UpdateProducts({
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
  targetID,
  type,
}) {
  const axiosInstance = useAxiosInstance();
  const [name, setName] = useState("");
  const [sku, setSku] = useState("");
  const [productId, setProductID] = useState(null);
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [cardImage, setCardImage] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const [colors, setColors] = useState([]);

  const { colorways, loadOptions } = useFetchColorway({ type });

  const urlParams = useParams();

  useEffect(() => {
    async function fetchAndSetProduct() {
      const { data } = await axiosInstance.get(Product_API + targetID);
      console.log(data, "product data");
      setName(data?.singleProduct?.name);
      setColors(data?.colors);
      setSku(data?.singleProduct?.sku);
      setProductID(data?.singleProduct?.product_AliasId);
      setDescription(data?.singleProduct?.description);
      setCardImage(data?.singleProduct?.cardImage);
      urlParams.type == "apparel" &&
        setCategory(
          data?.singleProduct?.category !== undefined &&
            data?.singleProduct?.category !== null
            ? data?.singleProduct?.category
            : ""
        );
    }
    fetchAndSetProduct();
  }, [targetID, axiosInstance]);

  console.log("50category", category);

  useEffect(() => {
    async function fetchAndSetImages() {
      const { data } = await axiosInstance.get(PRODUCT_ALL_IMAGE + targetID);
      setImages(data);
      setPreviewImages(
        data.map((image) => ({
          _id: image._id,
          image: image.image,
          isNew: false,
        }))
      );
    }
    fetchAndSetImages();
  }, [targetID, axiosInstance]);

  const option = colorways?.map((colorway) => ({
    value: colorway._id,
    label: colorway.name,
  }));

  const newColors = colors?.map((color) => color._id);

  async function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("slug", slugify(name));
    urlParams.type == "apparel" && formData.append("category", category);

    formData.append("cardImage", cardImage);
    formData.append("sku", sku);
    formData.append("description", description);
    formData.append("type", type);

    images.forEach((image) => {
      formData.append("image", image);
    });

    if (newColors) {
      formData.append("colorWay", JSON.stringify(newColors));
    }

    const response = await axiosInstance.patch(
      Product_API + targetID,
      formData
    );

    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#292929",
      outline: "none",
      color: "white",
      width: "100%",
      border: "none",
      borderRadius: "5px",
      marginBottom: "10px",
      marginTop: "10px",
    }),

    input: (styles) => ({ ...styles, color: "white" }),
  };

  const values = colors?.map((color) => {
    return { value: color?._id, label: color.name };
  });

  const handleChange = (e) => {
    console.log(e);
    setColors([...colors, { _id: e.value, name: e.label }]);
  };

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Product Name`}
        value={name}
        placeholder={`Enter Product Name`}
        setState={setName}
      />
      <ImageInput
        state={cardImage}
        label={`Card Image`}
        value={cardImage}
        setState={setCardImage}
        placeholder={`Enter Product Card  image`}
        allowUpdateImage={true}
      />

      <div
        style={{
          marginTop: "10px",
        }}
      >
        <p className="input_field_label">Product Colorway</p>
        <AsyncSelect
          styles={selectStyles}
          isMulti
          cacheOptions
          closeMenuOnSelect={false}
          defaultOptions={colorways}
          value={values}
          placeholder="Add recommended color"
          options={option}
          onChange={(e) => e.map((color) => handleChange(color))}
          loadOptions={(inputValue, callback) => {
            loadOptions(inputValue, callback);
          }}
        />
      </div>

      {urlParams.type == "apparel" ? (
        <>
          <p className="input_field_label">Product Category</p>
          <SelectInput value={category ? category : ""} setState={setCategory}>
            <option value="" disabled hidden>
              Please Select Category
            </option>
            <option value="t-shirts">T-shirts</option>
            <option value="hoodies">Hoodies</option>
            <option value="jackets">Jackets</option>
            <option value="pants">Pants</option>
            <option value="shorts">Shorts</option>
          </SelectInput>
        </>
      ) : null}

      <ShortTextInput
        label={`Product SKU`}
        value={sku}
        placeholder={`Enter Product Sku`}
        setState={setSku}
      />

      <ShortTextInput label={`Product ID`} value={productId} disabled={true} />

      <ShortTextInput
        label={`Product Description`}
        value={description}
        placeholder={`Enter Product Description`}
        setState={setDescription}
      />

      <MultiImageInput
        fieldId="1"
        state={images}
        setState={setImages}
        allowUpdateImage
        previewImages={previewImages}
        setPreviewImages={setPreviewImages}
      />

      <FormSubmitButton text="Update Product" loading={loading} />
    </Form>
  );
}

export default UpdateProducts;
