"use client"

import { 
  useReadContract,
  useWriteContract,
  useAccount,
} from 'wagmi'
import abi from "./abi/abi.json"
import { toast } from 'react-toastify'

const CHEBCENTER_ADDRESS = process.env.REACT_APP_CHEB_CENTER_ADDRESS

export const ROLES = {
  ADMIN_ROLE: '0xa49807205ce4d355092ef5a8a18f56e8913cf4a201fbe287825b095693c21775',
  VERIFIER_ROLE: '0x0ce23c3e399818cfee81a7ab0880f714e53d7672b08df0fa62f2843416e1ea09',
  EXECUTIVE_ROLE: '0xe4eefbff6f5adc6b3fe0d11f14a3859decb3df1f4c82933fc6c1f8b498cc4a7d',
  DEFAULT_ADMIN_ROLE: '0x0000000000000000000000000000000000000000000000000000000000000000'
}

export const useAddRoleMembers = () => {
  const { writeContract, isPending, data } = useWriteContract()
  const { isConnected } = useAccount()

  const addMembers = async (role, members) => {
    if (!role || !members || members.length === 0) {
      throw new Error('Role and members are required')
    }
    if (members.length > 50) {
      throw new Error('Maximum 50 members allowed')
    }

    try {
      writeContract({
        address: CHEBCENTER_ADDRESS,
        abi,
        functionName: 'addRoleMembers',
        args: [role, members],
        enabled: isConnected,
      })
    } catch (error) {
      console.error('Error adding role members:', error)
      if(error.reason === 'AlreadyHasRole') toast.error('Some members already have the role')
      throw error
    }
  }

  return { addMembers, isPending, data }
}

export const useRemoveRoleMembers = () => {
  const { writeContract, isPending, data } = useWriteContract()
  const { isConnected } = useAccount()

  const removeMembers = async (role, members) => {
    if (!role || !members || members.length === 0) {
      throw new Error('Role and members are required')
    }
    if (members.length > 50) {
      throw new Error('Maximum 50 members allowed')
    }

    try {
      writeContract({
        address: CHEBCENTER_ADDRESS,
        abi,
        functionName: 'removeRoleMembers',
        args: [role, members],
        enabled: isConnected,
      })
    } catch (error) {
      console.error('Error removing role members:', error)
      throw error
    }
  }

  return { removeMembers, isPending, data }
}

export const useGrantRole = () => {
  const { writeContract, isPending, data } = useWriteContract()
  const { isConnected } = useAccount()

  const grantRole = async (role, account) => {
    if (!role || !account) {
      throw new Error('Role and account address are required')
    }

    try {
      writeContract({
        address: CHEBCENTER_ADDRESS,
        abi,
        functionName: 'grantRole',
        args: [role, account],
        enabled: isConnected,
      })
    } catch (error) {
      console.error('Error granting role:', error)
      throw error
    }
  }

  return { grantRole, isPending, data }
}

export const useRevokeRole = () => {
  const { writeContract, isPending, data } = useWriteContract()
  const { isConnected } = useAccount()

  const revokeRole = async (role, account) => {
    if (!role || !account) {
      throw new Error('Role and account address are required')
    }

    try {
      writeContract({
        address: CHEBCENTER_ADDRESS,
        abi,
        functionName: 'revokeRole',
        args: [role, account],
        enabled: isConnected,
      })
    } catch (error) {
      console.error('Error revoking role:', error)
      throw error
    }
  }

  return { revokeRole, isPending, data }
}

export const useRenounceRole = () => {
  const { writeContract, isPending, data } = useWriteContract()
  const { isConnected } = useAccount()

  const renounceRole = async (role, account) => {
    if (!role || !account) {
      throw new Error('Role and account address are required')
    }

    try {
      writeContract({
        address: CHEBCENTER_ADDRESS,
        abi,
        functionName: 'renounceRole',
        args: [role, account],
        enabled: isConnected,
      })
    } catch (error) {
      console.error('Error renouncing role:', error)
      throw error
    }
  }

  return { renounceRole, isPending, data }
}

export const useHasRole = (role, account) => {
  const { isConnected } = useAccount()

  return useReadContract({
    address: CHEBCENTER_ADDRESS,
    abi,
    functionName: 'hasRoleMember',
    args: [role, account],
    enabled: isConnected && Boolean(role) && Boolean(account),
  })
}

export const useHasRoleMembers = (role, accounts) => {
  const { isConnected } = useAccount()

  return useReadContract({
    address: CHEBCENTER_ADDRESS,
    abi,
    functionName: 'hasRoleMembers',
    args: [role, accounts],
    enabled: isConnected && Boolean(role) && Boolean(accounts) && accounts.length > 0 && accounts.length <= 50,
  })
}

export const useGetRoleAdmin = (role) => {
  const { isConnected } = useAccount()

  return useReadContract({
    address: CHEBCENTER_ADDRESS,
    abi,
    functionName: 'getRoleAdmin',
    args: [role],
    enabled: isConnected && Boolean(role),
  })
}

export const useContractPause = () => {
  const { writeContract, isPending, data } = useWriteContract()
  const { isConnected } = useAccount()

  const pauseContract = async () => {
    try {
      writeContract({
        address: CHEBCENTER_ADDRESS,
        abi,
        functionName: 'pause',
        enabled: isConnected,
      })
    } catch (error) {
      console.error('Error pausing contract:', error)
      throw error
    }
  }

  return { pauseContract, isPending, data }
}

export const useContractUnpause = () => {
  const { writeContract, isPending, data } = useWriteContract()
  const { isConnected } = useAccount()

  const unpauseContract = async () => {
    try {
      writeContract({
        address: CHEBCENTER_ADDRESS,
        abi,
        functionName: 'unpause',
        enabled: isConnected,
      })
    } catch (error) {
      console.error('Error unpausing contract:', error)
      throw error
    }
  }

  return { unpauseContract, isPending, data }
}

export const useIsContractPaused = () => {
  const { isConnected } = useAccount()

  return useReadContract({
    address: CHEBCENTER_ADDRESS,
    abi,
    functionName: 'paused',
    enabled: isConnected,
  })
}

export const useSupportsInterface = (interfaceId) => {
  const { isConnected } = useAccount()

  return useReadContract({
    address: CHEBCENTER_ADDRESS,
    abi,
    functionName: 'supportsInterface',
    args: [interfaceId],
    enabled: isConnected && Boolean(interfaceId),
  })
}