import { useContext, useState } from "react";
import AppContext from "../../../../AppContext/AppContext";

import EmployeesHeader from "./components/EmployeesHeader";
import EmployeesTable from "./components/EmployeesTable";
import EmployeesModal from "./components/EmployeesModal";
import useFetchEmployees from "./Hooks/useFetchEmployees";

function Employees() {
  const { employee } = useContext(AppContext);

  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [archive, setArchive] = useState(false);
  const [filter, setFilter] = useState("all");

  const employees = useFetchEmployees({ filter, toggleFetch });

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  function handleClose() {
    setShowModal(false);
  }

  return (
    <>
      <div className="crud_holder">
        <EmployeesHeader
          employees={employees}
          setFilter={setFilter}
          filter={filter}
        />
        <EmployeesTable
          employee={employee}
          employees={employees}
          triggerFetch={triggerFetch}
          setShowModal={setShowModal}
          setTargetID={setTargetID}
          setArchive={setArchive}
          setShowDeleteSection={setShowDeleteSection}
        />
      </div>
      <EmployeesModal
        employee={employee}
        showModal={showModal}
        handleClose={handleClose}
        targetID={targetID}
        triggerFetch={triggerFetch}
        showDeleteSection={showDeleteSection}
        archive={archive}
        setArchive={setArchive}
        setShowModal={setShowModal}
        setShowDeleteSection={setShowDeleteSection}
      />

      
    </>
  );
}

export default Employees;
