import { useState } from "react";
import Modal from "../../Partials/Elements/Modal/Modal";
import UpdateProfile from "./UpdateProfile";
import ChangePassword from "./ChangePassword";

export default function Profile() {
    const [showModal, setShowModal] = useState(false);

    function handleClose() {
        setShowModal(false);
    }


    return (
        <>
            <UpdateProfile />
            <p>
                Want to change password?
                <button onClick={() => setShowModal(true)}>Click here</button>
            </p>

            {showModal && (
                <Modal
                    handleClose={handleClose}
                    modalHeading="Change Password"
                >
                    <ChangePassword setShowModal={setShowModal} />
                </Modal>
            )}

        </>
    );
}
