import { createContext, useState } from "react";
import useAxiosAuthInstance from "../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance";
import { LOGIN_API, REGISTER_API } from "../Utilities/APIs/AuthAPIs/AuthAPIs";
import { toast } from "react-toastify";

// import { useNavigate } from "react-router-dom";

const AppContext = createContext({})


function AppContextProvider({ children }) {

    // const navigate = useNavigate()
    const axiosAuthInstance = useAxiosAuthInstance()
    const [isLoading, setIsLoading] = useState(false);
    const [employee, setEmployee] = useState(JSON.parse(localStorage.getItem('employee')))
    const [orderValue,setOrderValue] = useState("")
    const [paidValue,setPaidValue] =useState("")
    // const [isLoading, setIsLoading] = useState(false)

    async function register(employeeData) {

        try {
        const response = await axiosAuthInstance.post(REGISTER_API, employeeData);

            if (response.data) {
                setEmployee(response.data)
                localStorage.setItem('employee', JSON.stringify(response.data))
            }

        } catch (error) {
            // console.log(error.message);
        }
    }

    async function login(employeeData) {
        try {
            // setIsLoading(true)
            const response = await axiosAuthInstance.post(LOGIN_API, employeeData);
        

            if (response.data) {
                setEmployee(response.data)
                localStorage.setItem('employee', JSON.stringify(response.data))
                // setIsLoading(false)
            }

        } catch (error) {
            toast.error(error?.response?.data?.message || "Something Went Wrong"  )

        }
    }

    function logout() {
        setEmployee(null)
        localStorage.removeItem('employee')
        // navigate('/auth/login')
    }


    return (
        <AppContext.Provider
            value={{
                register,
                login,
                logout,
                employee,
                isLoading,
				setIsLoading,
                orderValue,
                setOrderValue,
                paidValue,
                setPaidValue
                
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export {
    AppContextProvider
}

export default AppContext;