import { useEffect, useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import {
    ALL_SELLER_API,
    PAYMENT_TO_SELLER
} from "../../../Utilities/APIs/APIs";
import Form from "../../Partials/Layouts/Forms/Form";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import EmailInput from "../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function UpdateProfile() {
    const axiosInstance = useAxiosInstance();

    const [fullname, setName] = useState("");
    const [email, setEmail] = useState("");
    const [cardImage, setCardImage] = useState("");

    async function fetchAndSetProfileData() {
        try {
            const { data } = await axiosInstance.get("/profile");
            setName(data?.data?.name);
            setEmail(data?.data?.email);
            setCardImage(data?.data?.image); 
        } catch (error) {
            console.error("Error fetching profile data:", error);
        }
    }

    useEffect(() => {
     fetchAndSetProfileData();
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        const getProfileData = new FormData();
        getProfileData.append("name", fullname);
        getProfileData.append("email", email);
        getProfileData.append("image", cardImage);

        try {
            const response = await axiosInstance.post("/profile", getProfileData);
            if (response) {
                fetchAndSetProfileData();
            }
        } catch (error) {
            console.error("Error updating profile:", error);
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ImageInput
                state={cardImage}
                label="Card Image"
                value={cardImage}
                setState={setCardImage}
                placeholder="Enter Product Card image"
                allowUpdateImage={true}
            />
            <ShortTextInput
                label="Name"
                value={fullname}
                placeholder="Please Enter Name"
                setState={setName}
            />
            <EmailInput
                label="Email"
                value={email}
                placeholder="Please Enter your Email"
                setState={setEmail}
            />
            <FormSubmitButton text="Update" />
        </Form>
    );
}

export default UpdateProfile;
