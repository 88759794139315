import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import "./ArchiveItem.css";

function ArchiveItem({
  api,
  targetID,
  setShowDeleteSection,
  setShowModal,
  triggerFetch,
  isArchive,
}) {
  const axiosInstance = useAxiosInstance();
  async function handleArchive(id) {
    const updatedIsArchive = !isArchive;
    console.log("isArchive 14",isArchive, updatedIsArchive)

    const archiveData = { isArchive: updatedIsArchive };
    const res = await axiosInstance.patch(api + id, archiveData);

    if (res.data) {
      setShowModal(false);
      setShowDeleteSection(false);
      triggerFetch();
    }
  }

  return (
    <div className="delete_item">
      <div className="delete_content">
        <h1>Are you sure?</h1>
        <p>
          Please confirm you want to {isArchive ? "Unarchive" : "Archive"} this.
        </p>
        <div className="btn_container">
          <button onClick={() => handleArchive(targetID)} className="delete">
            {isArchive ? "Unarchive" : "Archive"}
          </button>

          <button
            className="cancel"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ArchiveItem;
