import { useContext, useState } from "react";

import AppContext from "../../../../AppContext/AppContext";
import useFetchSeller from "./Hook/useFetchSeller";
import SellerHeader from "./components/SellerHeader";
import SellerTable from "./components/SellerTable";
import SellerModal from "./components/SellerModal/SellerModal";

function Sellers() {
  const { employee } = useContext(AppContext);
  
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [archive, setArchive] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const sellers = useFetchSeller({toggleFetch});

  
  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }


  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
  }

  return (
    <>
      <div className="crud_holder">
        <SellerHeader sellers={sellers} />
        <SellerTable
          sellers={sellers}
          setShowModal={setShowModal}
          setTargetID={setTargetID}
          triggerFetch={triggerFetch}
          setShowViewSection={setShowViewSection}
          setShowDeleteSection={setShowDeleteSection}
          setArchive={setArchive}

        />
      </div>
      <SellerModal
        employee={employee}
        showModal={showModal}
        handleClose={handleClose}
        targetID={targetID}
        showViewSection={showViewSection}
        setShowDeleteSection={setShowDeleteSection}
        triggerFetch={triggerFetch}
        showDeleteSection={showDeleteSection}
        archive={archive}
        setArchive={setArchive}
        setShowModal={setShowModal}
      />


    </>
  );
}

export default Sellers;
