import React, { useEffect, useState } from 'react'
import { RiCheckFill, RiCloseFill } from 'react-icons/ri'
import { useAccount } from 'wagmi'
import {
  ROLES,
  useHasRole,
} from '../../../../../config/accesscontrol/chebcenter/ChebCenter'

export default function RoleManageHeader() {
  const { address } = useAccount()
  const [Address, setAddress] = useState(address || '')
  const [roles, setRoles] = useState([])

  const { data: isAdmin } = useHasRole(ROLES.ADMIN_ROLE, Address)
  const { data: isVerifier } = useHasRole(ROLES.VERIFIER_ROLE, Address)
  const { data: isExecutive } = useHasRole(ROLES.EXECUTIVE_ROLE, Address)
  const { data: isDefaultAdmin } = useHasRole(ROLES.DEFAULT_ADMIN_ROLE, Address)

  useEffect(() => {
    setRoles([
      { label: 'Default Admin', value: isDefaultAdmin },
      { label: 'Admin', value: isAdmin },
      { label: 'Verifier', value: isVerifier },
      { label: 'Executive', value: isExecutive },
    ])
  }, [isAdmin, isVerifier, isExecutive, isDefaultAdmin, Address])

  return (
    <div className='body_header'>
      <div className='flex items-center w-1/2 gap-4'>
        <h2 className='heading text-4xl'>Address:</h2>
        <input
          type='text'
          placeholder='0x...'
          className='address_input w-full mb-0'
          value={Address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </div>
      <div className='role_status'>
        {roles.map((role, index) => (
          <div className='flex items-center' key={index}>
            <span>{role.label}</span>
            {role.value ? (
              <RiCheckFill
                className='icon_active'
                style={{ fontSize: '24px' }}
              />
            ) : (
              <RiCloseFill
                className='icon_notactive'
                style={{ fontSize: '24px' }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
