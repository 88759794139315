import { useState } from "react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import NumberInput from "../../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { COLOUR_API } from "../../../../../Utilities/APIs/APIs";
import { toast } from "react-toastify";

function CreateColor({ setShowCreateForm, setShowModal, triggerFetch, type }) {
  const axiosInstance = useAxiosInstance();

  const [name, setName] = useState("");
  const [precedence, setPrecedence] = useState(1);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = { name, type, precedence };
    if (!name) {
      toast.error("Please enter name");
      return false;
    }

    const response = await axiosInstance.post(COLOUR_API, itemData);
    if (response?.response?.status === 400) {
      toast.error("Colour already exist");
      return false;
    }
    if (response.data) {
      toast.success("Colour Created Successfully");
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Colour Name`}
        value={name}
        placeholder={`Enter Colour Name`}
        setState={setName}
      />

      {/* <NumberInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Enter Precedence`}
        setState={setPrecedence}
      /> */}

      <FormSubmitButton text="Create Colour" />
    </Form>
  );
}

export default CreateColor;
