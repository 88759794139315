import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { Product_API } from "../../../../../../Utilities/APIs/APIs";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import slugify from "react-slugify";
import ImageInput from "../../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import MultiImageInput from "../../../../../Partials/Layouts/Forms/FormInputs/MultiImageInput/MultiImageInput";
import useFetchColorway from "./Hooks/useFetchColorway";
import AsyncSelect from "react-select/async";
import SelectInput from "../../../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import { useParams } from "react-router-dom";

function CreateProduct({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  type,
}) {
  const axiosInstance = useAxiosInstance();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [colorWay, setColorWay] = useState([]);
  const [category, setCategory] = useState(null);
  const [sku, setSku] = useState("");
  const [productId, setProductID] = useState(null);
  const [description, setDescription] = useState("");
  const [cardImage, setCardImage] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [images, setImages] = useState([]);
  const urlParams = useParams();

  useEffect(()=>{
    function generateRandomProductNumber() {
      const prefix = "PRD";
      const randomNumber = Math.floor(Math.random() * 9000) + 1000;
      return prefix + randomNumber;
  }
  const newProductNumber = generateRandomProductNumber();
  setProductID(newProductNumber)  

  },[])


  const { colorways, loadOptions } = useFetchColorway({ type });

  async function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();

    formData.append("name", name);
    formData.append("slug", slugify(name));
    urlParams.type == "apparel" && formData.append("category", category);
    formData.append("cardImage", cardImage);
    formData.append("sku", sku);
    formData.append("product_AliasId", productId);
    formData.append("description", description);
    formData.append("type", type);
    images.forEach((image) => {
      formData.append("image", image);
    });

    if (colorWay) {
      formData.append("colorWay", JSON.stringify(colorWay));
    }

    const response = await axiosInstance.post(Product_API, formData);

    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      setLoading(false);
      triggerFetch();
    } else {
      setLoading(false);
    }
  }

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#292929",
      outline: "none",
      color: "white",
      width: "100%",
      border: "1px solid #ccc",
      borderRadius: "5px",
      marginBottom: "10px",
      marginTop: "10px",
    }),
    option: (styles) => ({
      ...styles,
      color: "black",
    }),

    input: (styles) => ({ ...styles, color: "white" }),
  };

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Product Name`}
        value={name}
        placeholder={`Enter Product Name`}
        setState={setName}
      />
      <ImageInput
        label={`Card Image`}
        value={cardImage}
        setState={setCardImage}
        placeholder={`Enter Product Card  image`}
        allowCreateImage={true}
      >
        Attach Preview Image (1MB or Less)
      </ImageInput>

      <div
        style={{
          marginTop: "10px",
        }}
      >
        <p className="input_field_label">Product Colorway</p>
        <AsyncSelect
          styles={selectStyles}
          isMulti
          cacheOptions
          closeMenuOnSelect={false}
          defaultOptions={colorways}
          placeholder="Add recommended color"
          options={colorways}
          onChange={(e) => setColorWay(e.map((id) => id.value))}
          loadOptions={(inputValue, callback) => {
            loadOptions(inputValue, callback);
          }}
        />
      </div>

      {
        urlParams.type == "apparel" ? (
          <>
            <p className="input_field_label">Product Category</p>
            <SelectInput setState={setCategory}>
              <option disabled hidden selected>
                Please Select Category
              </option>
              <option value="t-shirts">T-shirts</option>
              <option value="hoodies">Hoodies</option>
              <option value="jackets">Jackets</option>
              <option value="pants">Pants</option>
              <option value="shorts">Shorts</option>
            </SelectInput>
          </>

        ) : null
      }



      <ShortTextInput
        label={`Product SKU`}
        value={sku}
        placeholder={`Enter Product SKU`}
        setState={setSku}
      />

      <ShortTextInput
        label={`Product ID`}
        value={productId}
        disabled={true}  
      />


      <ShortTextInput
        label={`Product Description`}
        value={description}
        placeholder={`Enter Product Description`}
        setState={setDescription}
      />

      <MultiImageInput
        fieldId="1"
        state={images}
        setState={setImages}
        allowCreateImage
        previewImages={previewImages}
        setPreviewImages={setPreviewImages}
      >
        Attach Main Images (5 Images Max, each 1MB or Less)
      </MultiImageInput>

      <FormSubmitButton text="Create Product" loading={loading} />
    </Form>
  );
}

export default CreateProduct;
