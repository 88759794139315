import { useState } from "react";
import PasswordInput from "../../Partials/Layouts/Forms/FormInputs/PasswordInput/PasswordInput";
import Form from "../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import { toast } from "react-toastify";


export default function ChangePassword({ setShowModal }) {
    const axiosInstance = useAxiosInstance();
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    async function handleSubmit(e) {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            toast.error("New password and old password not matching");
        }

        const itemData = new FormData();
        itemData.append("oldPassword", oldPassword);
        itemData.append("newPassword", newPassword);

        try {
            await axiosInstance.post("change-password", itemData)
            toast.success("Password Updated Successfully")
            setShowModal(false)
        }
        catch (error) {
            console.error(error.response);
            toast.error(error?.response?.data?.message || "Failed to update password"  )
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <PasswordInput
                label={"Enter Old Password"}
                value={oldPassword}
                placeholder={"Please Enter Old Password"}
                setState={setOldPassword}
            />

            <PasswordInput
                label={"Enter New Password"}
                value={newPassword}
                placeholder={"Please Enter New Password"}
                setState={setNewPassword}
            />

            <PasswordInput
                label={"Re-Enter New Password"}
                value={confirmPassword}
                placeholder={"Please Re-Enter New Password"}
                setState={setConfirmPassword}
            />
            <FormSubmitButton text="Update" />
        </Form>
    );
}
